<template>
  <div class="animated fadeIn">
    <div v-if="loadingReallocation" class="row justify-content-center">
      <div class="col mt-3 mb-3">
        <animation />
      </div>
    </div>

    <b-form v-else @submit.prevent="save" novalidate :validated="isValid" autocomplete="off">
      <b-row class="mx-md-n3 mx-0">
        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mb-3">
          <label for="warehouseSelect">Selecione o Armazém da realocação:</label>
          <div v-if="loadingWarehouses">
            <animation />
          </div>
          <model-list-select
            v-else
            id="warehouseSelect"
            :list="warehouses"
            option-value="id"
            option-text="name"
            v-model="selectedWarehouse"
            placeholder="Armazém"
            :isDisabled="saving || (singleLocation && Boolean(formData.id))"
          />
        </div>
      </b-row>

      <b-row v-if="singleLocation" class="mx-md-n3 mx-0 mb-3">

        <div v-for="(locationField, index) in locationFields" class="col-sm-6 col-12 mb-sm-0 mb-3 pr-sm-3 pr-0" :key="'location_field_' + index">
          <div class="row align-items-end">

            <div class="col-sm-10 col-10 pr-2">
              <label>{{locationField.label}}:</label>
              <model-list-select
                :list="locations"
                option-value="id"
                option-text="name"
                v-model="locationField.value"
                placeholder="Digite para pesquisar..."
                @searchchange="searchLocation($event)"
                :isDisabled="saving || !isWarehouseSelected || Boolean(formData.id)"
                :filterPredicate="alwaysTrueFilterPredicate"
                @input="locationChanged(locationField)"
              />
            </div>

            <div class="col-sm-2 col-2 px-0">
              <div v-if="searchLocationInProcess">
                <animation />
              </div>
              <b-button
                v-else
                variant="secondary"
                @click.prevent="openLocationBarcodeInput(locationField.key)"
                :disabled="saving || !isWarehouseSelected || Boolean(formData.id)"
              >
                <i class="fa fa-barcode"></i>
              </b-button>
            </div>

          </div>
        </div>

      </b-row>

      <b-row class="mx-md-n3 mx-1">
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-10 col-10 mb-md-0 mb-3">
          <label for="itemSelect">Selecione o Item:</label>
          <model-list-select
            id="itemSelect"
            :list="items"
            option-value="id"
            option-text="label"
            v-model="selectedItem"
            placeholder="Digite para pesquisar..."
            @searchchange="searchItem"
            :filterPredicate="alwaysTrueFilterPredicate"
            :isDisabled="singleLocation"
          />
        </div>
        <div class="col-xl-1 col-lg-1 col-md-1 col-sm-2 col-2">
          <div v-if="searchItemInProcess">
            <animation/>
          </div>
        </div>

        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
          <label for="quantityInput">Quantidade:</label>
          <div class="row mr-0 align-items-center">
            <div class="col">
              <b-input v-model="quantity" id="quantity" class="margin-right" onFocus="this.setSelectionRange(0, this.value.length)"></b-input>
            </div>
            <div class="col-auto">
              <b-button variant="outline-primary" @click.prevent="addItem" :disabled="!isItemSelected || !Boolean(quantity) || !isWarehouseSelected">
                <i v-if="editIndex == null" class="fa fa-check"></i>
                <i v-else class="fa fa-check"></i>
              </b-button>
            </div>
          </div>
        </div>
      </b-row>

      <div v-if="!formData.orderItems.length" class="mb-3 mt-3">
        <div class="row bg-light mx-0 py-3">
          <div class="col text-center">
            Nenhum Item adicionado
          </div>
        </div>
      </div>

      <div v-if="formData.orderItems.length" class="mb-3 mt-3">
        <div v-for="(orderItem, index) in formData.orderItems" class="row py-3 border mx-0" :key="index"
          :class="{ 'bg-light': index % 2 != 0 }">
          <div class="col-xl-7 col-lg-12 col-md-12 col-sm-12 col-12">
            <span class="font-weight-bold">Item: </span>{{orderItem.item ? orderItem.item.description : ''}}
          </div>
          <div class="col-xl-2 col-lg-8 col-md-8 col-sm-8 col-12 mt-xl-0 mt-1">
            <span class="font-weight-bold">Quantidade: </span>{{orderItem.quantity}}
          </div>
          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-12 mt-xl-0 mt-1">
            <b-button size="sm" @click.prevent="editItem(index)" :disabled="editIndex === index || saving || Boolean(orderItem.alocatedQuantity)"
              class="mr-sm-1 mr-4 mobile-btn-45">
              <i class="fa fa-edit"></i> Editar
            </b-button>
            <b-button size="sm" variant="danger" @click.prevent="openDeleteModal(index)"
              class="ml-1 mobile-btn-45" :disabled="saving || Boolean(orderItem.alocatedQuantity)"
            >
              <i class="fa fa-trash"></i> Remover
            </b-button>
          </div>
        </div>
      </div>

      <div class="row mx-md-n3 mx-1">
        <div class="col">
          <button-back></button-back>
          <b-button v-if="!saving" type="submit" variant="primary" class="btn-lg float-right"
            :disabled="!isWarehouseSelected"
          >
            Salvar
          </b-button>
        </div>
        <div v-if="saving" class="col-auto">
          <animation/>
        </div>
      </div>

      <div v-if="singleLocation" class="row mx-md-n3 mx-1 justify-content-end mt-1">
        <div class="col-auto font-weight-bold">
          Aviso! Após salvar o pedido o armazém e os endereços não podem ser alterados!
        </div>
      </div>
    </b-form>

    <confirmation-modal
      id="delete-confirmation-modal"
      msg="Tem certeza de que deseja remover esse item da realocação?"
      :ok="deleteItem"
    ></confirmation-modal>

    <barcode-input-modal
      id="location-barcode-input-modal"
      :attempts="locationBarcodeAttempts"
      :loading="loadingLocationBarcode"
      :ok="readLocationBarcode"
    ></barcode-input-modal>
  </div>
</template>

<script>
  import CForm from './c-form'
  import ButtonBack from './c-button-back'
  import { ModelListSelect } from 'vue-search-select'
  import Animation from '@/components/loaders/animation'
  import ConfirmationModal from '@/components/common/confirmation-modal'
  import { httpClient } from '@/service'
  import shared from '@/shared/shared'
  import BarcodeInputModal from "@/components/common/barcode-input-modal";

  export default {
    name: 'form-reallocations',
    components: {
      Animation,
      CForm,
      ModelListSelect,
      ButtonBack,
      ConfirmationModal,
      BarcodeInputModal
    },

    data () {
      return {
        formData: {
          orderItems: []
        },
        isValid: false,
        searchItemInProcess: false,
        items: [],
        selectedItem: {},
        quantity: '',
        orderItemsFields: [
          { key: 'item.description', label: 'Item'},
          { key: 'quantity', label: 'Quantidade'},
          { key: 'actions', label: 'Ações' },
        ],
        editIndex: null,
        deleteIndex: null,
        warehouses: [],
        selectedWarehouse: {},
        loadingWarehouses: false,
        saving: false,
        itemSearchTimeout: null,
        loadingReallocation: false,
        singleLocation: false,
        locationFields: [
          { key: 'sourceLocation', label: 'Endereço de origem', value: {} },
          { key: 'destinationLocation', label: 'Endereço de destino', value: {} }
        ],
        searchLocationInProcess: false,
        locations: [],
        locationSearchTimeout: null,
        loadingLocationBarcode: false,
        locationBarcodeAttempts: 0,
        selectedLocationKey: null
      }
    },

    mounted() {
      this.formData = {};
      this.findReallocation();
    },

    computed: {
      isWarehouseSelected() {
        return this.selectedWarehouse && this.selectedWarehouse.id;
      },

      isItemSelected() {
        return this.selectedItem && this.selectedItem.id;
      }
    },

    methods: {
      async searchItem (searchText) {
        if (!this.searchItemInProcess && (
          (searchText && searchText.length) || !this.selectedItem || !this.selectedItem.id
        )) {
          if (this.itemSearchTimeout) {
            clearTimeout(this.itemSearchTimeout);
          }

          this.itemSearchTimeout = setTimeout(() => {;
            this.searchItemInProcess = true

            if (searchText && searchText.length) {
              httpClient
              .post(`${process.env.VUE_APP_API_URL}items/condition?page=${0}&size=${10}`, {
                conditions: [
                  {
                    logicalOperator: 'OR',
                    conditions: [
                      {
                        field: 'description',
                        conditionalOperator: 'LIKE_START',
                        value: searchText
                      },
                      {
                        field: 'ref',
                        conditionalOperator: 'LIKE_END',
                        value: shared.mountReduzidoFilter(searchText)
                      },
                      {
                        field: 'sku',
                        conditionalOperator: 'LIKE',
                        value: searchText
                      },
                      {
                        field: 'gtin',
                        conditionalOperator: 'LIKE',
                        value: searchText
                      }
                    ]
                  }
                ]
              })
              .then(({ data }) => {
                this.items = this.handleData(data);
                this.setItemsLabel();
              })
              .catch((error) => {
                this.searchItemInProcess = false
                if (error.message) {
                  this.$notify.error(error)
                }
              });
            } else {
              httpClient
              .get(`${process.env.VUE_APP_API_URL}items/?page=${0}&size=${10}`)
              .then(({ data }) => {
                this.items = this.handleData(data);
                this.setItemsLabel();
              })
              .catch((error) => {
                this.searchItemInProcess = false
                if (error.message) {
                  this.$notify.error(error)
                }
              });
            }
          }, 500);
        }
      },

      handleData(data) {
        this.searchItemInProcess = false
        this.totalRows = data.data.totalElements
        const items = data.data.content

        return items
      },

      addItem () {
        let index = this.formData.orderItems.length;
        let quantity = Number(this.quantity.toString().replace(',', '.'));

        if (this.editIndex != null) {
          index = this.editIndex;
          let maxQuantity = this.formData.orderItems[index].maxQuantity;

          if (maxQuantity && quantity > maxQuantity) {
            this.$notify.textError('Quantidade ' + quantity + ' superior à quantidade alocada no endereço de origem (' + maxQuantity + ')');
            return;
          }
        }

        this.formData.orderItems[index] = {
          quantity: quantity,
          item: this.selectedItem
        };

        this.selectedItem = {};
        this.quantity = '';
        this.editIndex = null;
        this.$root.$emit('bv::refresh::table', 'orderItemsTable');
      },

      editItem(index) {
        this.editIndex = index;
        this.selectedItem = this.formData.orderItems[index].item;
        this.quantity = this.formData.orderItems[index].quantity;
      },

      openDeleteModal(index) {
        this.deleteIndex = index;
        this.$bvModal.show('delete-confirmation-modal');
      },

      deleteItem() {
        this.formData.orderItems.splice(this.deleteIndex, 1);
        this.deleteIndex = null;
        let formData = this.formData;
        this.formData = {};
        this.formData = formData;
      },

      save(event) {
        event.preventDefault()
        event.stopPropagation()

        if (!this.validateDestinationLocation(false)) {
          return;
        }

        if (!this.formData || !this.formData.orderItems || !this.formData.orderItems.length) {
          this.$notify.textError('Por favor inclua ao menos um item');
          return;
        }

        if (!this.validateDestinationLocation(true)) {
          return;
        }

        if (this.formData && this.formData.orderItems) {
          this.formData.orderItems.forEach(oi => {
            if (oi.quantity) {
              oi.quantity = Number(oi.quantity.toString().replace(',', '.'));
            }
          });
        }

        this.formData.stockLocale = this.selectedWarehouse;
        if (this.selectedWarehouse) {
          this.formData.companyGroup = this.selectedWarehouse.companyGroup;
        }

        if (!this.formData.ref || !this.formData.ref.length) {
          this.formData.ref = 'REALLOCATION_' + new Date().getTime();
        }

        if (!this.formData.departureDate) {
          this.formData.departureDate = new Date();
        }

        this.saving = true;
        let promise;
        this.saving = true;
        if (this.formData.id) {
          promise = httpClient.put(`${process.env.VUE_APP_API_URL}orders/`, this.formData);
        } else {
          promise = httpClient.post(`${process.env.VUE_APP_API_URL}orders/reallocation/`, this.formData);
        }

        promise.then(data => {
          this.saving = false;
          this.$notify.success('Salvo com sucesso');
          this.$router.push('/orders/item-reallocation/all');
          //this.$root.$emit('reloadOrdersCounts');
        }).catch(error => {
          this.saving = false;
          if (error.message) {
            this.$notify.error(error)
          } else {
            this.$notify.textError('Houve um erro ao salvar')
          }
        });
      },

      getWarehouses() {
        this.loadingWarehouses = true;
        httpClient
        .post(`${process.env.VUE_APP_API_URL}stock-locales/condition?page=${0}&size=${100}`, {
          conditions: [{
            field: 'active',
            value: true
          }]
        }).then(({ data }) => {
          this.loadingWarehouses = false;
          this.warehouses = data.data.content;
        })
        .catch((error) => {
          this.loadingWarehouses = false;
          if (error.message) {
            this.$notify.error(error)
          } else {
            this.$notify.textError("Houve um erro ao buscar os armazéns");
          }
        });
      },

      getItemReduzidoFromRef(ref) {
        return shared.getItemReduzidoFromRef(ref);
      },

      setItemsLabel() {
        if (this.items && this.items.length) {
          this.items.forEach(item => {
            item.label = this.getItemReduzidoFromRef(item.ref) + " - " + item.description;
          });
        }
      },

      findReallocation() {
        if (this.$route.params.id) {
          this.loadingReallocation = true;
          httpClient.get(`${process.env.VUE_APP_API_URL}orders/${this.$route.params.id}`)
          .then(data => {
            this.loadingReallocation = false;
            this.formData = data.data.data;
            this.setReallocationValues();

            this.locationFields.forEach(f => {
              let location = this.formData[f.key];

              if (location) {
                this.singleLocation = true;
                this.locations.unshift(location);
                f.value = location;

                if (this.formData.orderItems) {
                  this.formData.orderItems.forEach(oi => {
                    oi.maxQuantity = oi.quantity;
                  });
                }
              }
            });
          }).catch(error => {
            this.loadingReallocation = false;
            if (error.message) {
              this.$notify.error(error)
            } else {
              this.$notify.textError("Houve um erro ao buscar a realocação");
            }
          });
        } else {
          this.setReallocationValues();

          if (this.$route.query.singleLocation) {
            this.singleLocation = true;

            if (this.$route.query.sourceLocationId) {
              this.fillSourceLocation(this.$route.query.sourceLocationId);
            }
          }
        }
      },

      setReallocationValues() {
        this.selectedItem = this.formData.item || {}

        if (!this.formData.orderItems) {
          this.formData.orderItems = [];
        }

        if (!this.formData.stockLocale) {
          let locaStorageWarehouse = shared.getLocalStorageObj('order/warehouse');

          if (locaStorageWarehouse && locaStorageWarehouse.id) {
            this.formData.stockLocale = locaStorageWarehouse;
          }
        }

        this.searchItem(null);
        this.getWarehouses();

        if (this.formData && this.formData.stockLocale) {
          this.selectedWarehouse = this.formData.stockLocale;
        }
      },

      alwaysTrueFilterPredicate() {
        return true;
      },

      async searchLocation(searchText) {
        if (!this.searchLocationInProcess && !this.saving && searchText && searchText.length) {
          if (this.locationSearchTimeout) {
            clearTimeout(this.locationSearchTimeout);
          }

          let conditions = [
            {
              field: "name",
              conditionalOperator: "LIKE_START",
              value: searchText,
            },
            {
              field: "stockLocale.id",
              value: this.selectedWarehouse.id
            }
          ];

          this.locationSearchTimeout = setTimeout(() => {
            this.searchLocationInProcess = true;

            httpClient
              .post(
                `${
                  process.env.VUE_APP_API_URL
                }locations/condition?page=${0}&size=${10}`,
                {
                  conditions: conditions,
                }
              )
              .then(({ data }) => {
                this.searchLocationInProcess = false;

                data.data.content.slice().reverse().forEach(location => {
                  let index = this.locations.findIndex(l => l.id === location.id);

                  if (index > -1) {
                    this.locations.splice(index, 1);
                  }

                  this.locations.unshift(location);
                });

                if (!this.locations.find(l => !l.id)) {
                  this.locations.push({ id: null, name: "Nenhum" });
                }
              })
              .catch((error) => {
                this.searchLocationInProcess = false;
                if (error.message) {
                  this.$notify.error(error);
                }
              });
          }, 500);
        }
      },

      openLocationBarcodeInput(key) {
        this.selectedLocationKey = key;
        this.$bvModal.show("location-barcode-input-modal");
      },

      readLocationBarcode(barcode) {
        if (!this.loadingLocationBarcode) {
          if (barcode && barcode.length) {
            this.loadingLocationBarcode = true;
            httpClient
              .get(`${process.env.VUE_APP_API_URL}locations/barcode/${barcode}`)
              .then((data) => {
                this.loadingLocationBarcode = false;

                if (data.data.data) {
                  if (
                    data.data.data.stockLocale &&
                    data.data.data.stockLocale.id !== this.selectedWarehouse.id
                  ) {
                    this.$notify.textError(
                      "Endereço não pertence ao armazém " + this.selectedWarehouse.name
                    );
                  } else {
                    let field = this.locationFields.find(f => f.key === this.selectedLocationKey);
                    this.locations.unshift(data.data.data);
                    field.value = data.data.data;
                    this.locationChanged(field);
                    this.$bvModal.hide("location-barcode-input-modal");
                    this.$notify.success("Endereço encontrado");
                  }
                } else {
                  this.$notify.textError("Endereço não encontrado");
                }

                this.locationBarcodeAttempts++;
              })
              .catch((error) => {
                this.loadingLocationBarcode = false;
                if (error.message) {
                  this.$notify.error(error);
                } else {
                  this.$notify.textError(
                    "Houve um erro ao buscar o endereço pelo código de barras. Por favor tente novamente"
                  );
                }

                this.locationBarcodeAttempts++;
              });
          } else {
            this.$notify.textError(
              "Código inválido. Por favor insira um código de barras válido"
            );
          }
        }

        this.locationBarcodeAttempts++;
      },

      fillSourceLocation(locationId) {
        this.loadingReallocation = true;
        httpClient
        .get(`${process.env.VUE_APP_API_URL}locations/${locationId}`)
        .then((data) => {
          this.loadingReallocation = false;

          if (data.data.data) {
            this.locations.unshift(data.data.data);
            let field = this.locationFields.find(f => f.key === 'sourceLocation');
            field.value = data.data.data;
            this.locationChanged(field);

            if (data.data.data.stockLocale) {
              this.selectedWarehouse = data.data.data.stockLocale;
            }
          } else {
            this.$notify.textError("Endereço não encontrado");
          }
        })
        .catch((error) => {
          this.loadingReallocation = false;
          if (error.message) {
            this.$notify.error(error);
          } else {
            this.$notify.textError("Endereço não encontrado");
          }
        });
      },

      locationChanged(locationField) {
        if (this.formData.id) {
          return;
        }

        if (locationField.key === 'sourceLocation' && locationField.value && locationField.value.id) {
          this.getLocationItems(locationField.value.id);
        }
      },

      getLocationItems(locationId) {
        this.saving = true;
        httpClient.get(
          `${process.env.VUE_APP_API_URL}items/location/${locationId}?page=0&size=10000`
        ).then(({ data }) => {
          this.saving = false;
          let items = data.data.content.filter(i => i.totalAllocated > 0);
          this.formData.orderItems = [];

          if (items.length) {
            this.formData.orderItems = items.map(i => {
              return {
                item: i,
                quantity: i.totalAllocated,
                maxQuantity: i.totalAllocated
              }
            });
          } else {
            this.$notify.warn("Esse endereço não possui nenhum item alocado");
          }

          this.$root.$emit('bv::refresh::table', 'orderItemsTable');
        }).catch((error) => {
          this.saving = false;
          this.$notify.textError("Houve um erro ao buscar os itens do endereço");
          if (error.message) {
            this.$notify.error(error)
          }
        });
      },

      validateDestinationLocation(validateItems) {
        if (!this.singleLocation) return true;

        if (validateItems) {
          if (this.formData.destinationLocation.type === 'U') {
            return true;
          }

          if (this.formData.orderItems.length > 1) {
            this.$notify.textError('Tipo do endereço de destino não permite mais de um item');
            return false;
          }

          if (this.formData.destinationLocation.item && this.formData.destinationLocation.item.id !== this.formData.orderItems[0].item.id) {
            this.$notify.textError('Item incompatível com o endereço de destino');
            return false;
          }
        } else {
          this.locationFields.forEach(f => {
            this.formData[f.key] = f.value;
          });

          if (!this.formData.sourceLocation || !this.formData.sourceLocation.id) {
            this.$notify.textError('Selecione o endereço de origem');
            return false;
          }

          if (!this.formData.destinationLocation || !this.formData.destinationLocation.id) {
            this.$notify.textError('Selecione o endereço de destino');
            return false;
          }

          if (this.formData.sourceLocation.id === this.formData.destinationLocation.id) {
            this.$notify.textError('O endereço de destino deve ser diferente do endereço de origem');
            return false;
          }
        }

        return true;
      }

    }
  }

</script>

<style scoped>
</style>
